// @flow

import styled from 'styled-components';
import React from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Box } from '@latitude/box';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  FLEX_WRAP,
  DISPLAY,
  JUSTIFY_CONTENT,
  FONT_SIZE,
  ALIGN,
  LINE_HEIGHT,
  PADDING,
  FLEX_DIRECTION,
  ANALYTICS
} from '@latitude/core/utils/constants';
import RatesAndFees from '@latitude/rates-and-fees';
import { Text } from '@latitude/text';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Link } from '@latitude/link';
import Layout from '../components/layout';
import Image from '../components/Image/Image';
import PageData from '../data/pages/gem-visa-info.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import './credit-cards/gem-visa-card.scss';
import logoBlue from '../images/gem-latitude-logo-blue.svg';
import logoWhite from '../images/gem-latitude-logo-white.svg';
import pdfIcon from '../images/pdf-icon.png';

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  border-bottom: none;
`;

const GemVisaInfoPage = () => {
  return (
    <Layout noHeader noFooter>
      <MobileAppInstallPrompts />
      <AnalyticsLocationProvider location={ANALYTICS.LOCATION.HEADER}>
        <Box
          css={`
            .container,
            .container-sm,
            .container-md,
            .container-lg,
            .container-xl {
              max-width: 825px;
            }
          `}
        >
          <BasicHeader>
            <div className="container h-100 w-100 d-flex">
              <StyledHomeLink
                noStyle
                href="/"
                trackEventData={{ label: 'nav-home' }}
              >
                <Image
                  data-trackid="header-logo"
                  className="logo"
                  src={logoBlue}
                  alt="Gem by Latitude"
                />
              </StyledHomeLink>
            </div>
          </BasicHeader>
        </Box>
      </AnalyticsLocationProvider>
      <Divider />
      <main>
        <Box>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/gem-visa-card/"
            />
            <title>Gem Visa Info | Gem by Latitude</title>
            <meta
              name="description"
              content="Contact us to find important information about Gem Visa today. "
            />
          </Helmet>

          <AnalyticsLocationProvider location="Important information about Gem Visa">
            <Box.Section
              css={`
                max-width: 800px;
              `}
            >
              <SectionHeading>
                Important information about Gem Visa
              </SectionHeading>
              <Accordion
                fgColor={COLOR.BLACK}
                titleBgColor={COLOR.BLUE_SKY}
                contentBgColor="#eff7ff"
                css={`
                  h4 {
                    margin: 30px 0 10px;
                  }
                  h5 {
                    margin: 30px 0 10px;
                  }
                  p {
                    margin-bottom: 30px;
                  }
                  img {
                    height: 32px;
                    margin-right: 6px;
                  }
                `}
                items={[
                  {
                    id: 'privacy-notice',
                    title: 'Privacy Notice',
                    content: (
                      <div className="py-3 pl-4 pr-4">
                        <Heading4>Your application</Heading4>
                        <Heading5>Acknowledgement and Authorisation</Heading5>
                        <Text
                          display="flex"
                          css={`
                            align-items: center;
                          `}
                        >
                          <img src={pdfIcon} alt="Gem Visa Privacy Notice" />
                          <StyledLink
                            href="https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-privacy-notice.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'gemvisa-privacy-notice'
                            }}
                          >
                            Print Privacy notice
                          </StyledLink>
                        </Text>
                        <Text>
                          To: Latitude Financial Services Limited, PO Box 4058,
                          Shortland Street, Auckland 1140
                          (&quot;Latitude&quot;).
                        </Text>
                        <Text>
                          I apply for a Gem Visa account and credit card. If I
                          am offered and accept a Gem Visa account and credit
                          card, I understand I will be provided with a
                          disclosure statement and Conditions of Use, governing
                          my Gem Visa facility, and agree to be bound by these
                          documents.
                        </Text>
                        <Text>
                          I understand there is no obligation to provide
                          personal information including biometric information,
                          including driver licence details, but, if I fail to
                          supply any information requested, or do not agree to
                          any of the disclosures detailed in this
                          acknowledgement, my application may not be accepted. I
                          certify that all information supplied in my
                          application is true and complete, and where
                          applicable, I have the permission of my spouse/partner
                          to disclose his/her personal information.
                        </Text>
                        <Text>
                          I authorise Latitude to collect my information and
                          conduct an identity verification check to verify my
                          identity and address as required under Anti-Money
                          Laundering and Countering Financing of Terrorism Act
                          2009 (AML/CFT Act). I understand that as part of this
                          identity verification process, my biometric
                          information (a selfie photo and a short selfie video)
                          may be collected and compared to the photographic
                          identity document provided to verify my identity
                          against my claimed identity. Additionally, biometric
                          information may be collected to counter identity fraud
                          and minimise security risks, where applicable. I agree
                          that this authority may be provided to an external
                          identification service provider for the purpose of
                          identity verification.
                        </Text>
                        <Text>
                          I authorise Latitude to make all necessary enquiries
                          and receive information concerning my credit rating,
                          any outstanding fines, residence, employment and my
                          identity from other credit providers, credit
                          reporters, government agencies, my employer,
                          accountant, or other named persons or relatives and,
                          where applicable, to obtain such information relating
                          to my driver licence from Land Transport NZ as is
                          authorised by the Land Transport Act 1998 for the
                          purposes of considering my application, the
                          administration and protection of any credit provided
                          and considering any future request from me to increase
                          the credit limit on my account. I agree that this
                          authority may be provided to such parties for these
                          purposes. I also agree that my personal information
                          may be shared with NZ Post, any Gem Visa merchant, or
                          any other entity employed, engaged, or appointed by
                          Latitude for the purpose of verifying my identity.
                        </Text>
                        <Text>
                          I agree that any credit reporters&apos; monitoring
                          services may be used to receive updates if the
                          information held about me changes. I understand that
                          Latitude will give my information (including without
                          limitation, repayment history information, details
                          relating to my account, and payment default details)
                          to credit reporters for these purposes and that those
                          credit reporters may hold the information on their
                          systems and disclose it to authorised users of the
                          credit reporters&apos; services. I also understand
                          that information about my creditworthiness and credit
                          history may be disclosed to any credit provider named
                          in a credit report relating to me.
                        </Text>
                        <Text>
                          Where my application is initiated via a merchant
                          website, I agree that my information may be shared
                          with that merchant for the purpose of marketing its
                          products and services.
                        </Text>
                        <Text>
                          I authorise Latitude and its related entities share
                          and use all personal information held about me now or
                          in the future to offer me further finance, to consider
                          any future application of finance from me, and to
                          offer or provide me with details of other products and
                          services from time to time. I agree that such details
                          may be sent by mail, email, or telephone (including
                          SMS/ MMS). The personal information will be held at
                          the offices of Latitude. Where it may readily be
                          retrieved, I shall have access to it and the right to
                          request correction.
                        </Text>
                        <Text>
                          If I am offered and accept a Gem Visa account and
                          credit card, I consent to all disclosure documents
                          required for the purpose of the Credit Contracts and
                          Consumer Finance Act 2003 being sent either by mail or
                          in electronic form and by means of electronic
                          communication (either by email or accessible from a
                          website or by means of the Internet). I understand
                          that if I withdraw this consent at any time, I may be
                          required to pay a fee to cover the cost of providing
                          paper statements.
                        </Text>
                      </div>
                    )
                  },
                  {
                    id: 'conditions-of-use',
                    title: 'Conditions of use',
                    content: (
                      <div className="py-3 pl-4 pr-4">
                        <Text>
                          These are the Conditions of Use, which govern your Gem
                          Visa credit card.
                        </Text>
                        <Text
                          display="flex"
                          css={`
                            align-items: center;
                          `}
                        >
                          <img src={pdfIcon} alt="Gem Visa Conditions of Use" />
                          <StyledLink
                            href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-nz/cou.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gemvisa-cou' }}
                          >
                            Conditions of Use
                          </StyledLink>
                        </Text>
                      </div>
                    )
                  },
                  {
                    id: 'initial-disclosure-statement',
                    title: 'Initial Disclosure Statement',
                    content: (
                      <div className="py-3 pl-4 pr-4">
                        <Text>
                          This is the Initial Disclosure Statement that forms
                          part of your credit contract with Latitude Financial
                          Services Limited.
                        </Text>
                        <Text
                          display="flex"
                          css={`
                            align-items: center;
                          `}
                        >
                          <img
                            src={pdfIcon}
                            alt="Gem Visa Initial Disclosure Statement"
                          />
                          <StyledLink
                            href="https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'gemvisa-initial-disclosure-statement'
                            }}
                          >
                            Initial Disclosure Statement
                          </StyledLink>
                        </Text>
                      </div>
                    )
                  }
                ]}
              />
            </Box.Section>
          </AnalyticsLocationProvider>

          <Outer isResponsive>
            <Inner>
              <Heading5 color={COLOR.BLACK}>Product suitability</Heading5>
              <Text>
                When applying for a Gem Visa you are applying for a revolving
                credit contract (a general purpose credit card). When applying
                for this credit, you confirm that you require the credit on an
                ongoing basis.
              </Text>
            </Inner>
          </Outer>

          <AnalyticsLocationProvider location="Rates & Fees">
            <RatesAndFees
              css={`
                /**
                 * Overrides added below to account for UX request variant
                 * (Ref: CEPW-1022)
                 */
                background-color: ${COLOR.WHITE};

                [class^='RatesListBranded__RatesList'] {
                  @media (min-width: ${BREAKPOINT.XL}) {
                    max-width: 690px;
                  }
                }

                [class*='RatesListBranded__RateListItem']:nth-child(5) {
                  @media (min-width: ${BREAKPOINT.XL}) {
                    border-left: 1px solid ${COLOR.PURPLE};
                  }
                }

                .accordion__item > .bg-lightest {
                  background-color: ${COLOR.BLUE_SKY};
                  color: ${COLOR.BLACK};
                }

                .accordion-custom,
                .accordion-custom .accordion__row {
                  background-color: #eff7ff;
                }
              `}
              title="Rates & Fees"
              ratesListProps={{ data: PageData.ratesList }}
              accordionProps={{
                items: [
                  {
                    id: 'account-fees',
                    title: 'Account fees',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Annual Account Fee</strong>
                          </p>
                          <p className="w-100">
                            $65 (charged $32.50 half-yearly)
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Establishment Fee (One Off)</strong>
                          </p>
                          <p className="w-100">$55</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Paper Statement Fee</strong>
                          </p>
                          <p className="w-100">
                            $1 per statement (avoid this by opting out of paper
                            statements)
                          </p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'transaction-fees',
                    title: 'Transaction fees',
                    content: (
                      <StyledRatesBox className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Cash advance fee</strong>
                          </p>
                          <p className="w-100">
                            <ul>
                              <li>$2 - over the counter cash advance</li>
                              <li>$1 - NZ ATM withdrawal</li>
                              <li>$1.50 - International ATM Withdrawal</li>
                              <li>Other ATM fees may apply</li>
                            </ul>
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>International Transaction Fee</strong>
                          </p>
                          <p className="w-100">
                            2% of the purchase or cash advance
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Late fee</strong>
                          </p>
                          <p className="w-100">$15</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Payment handling fee</strong>
                          </p>
                          <p className="w-100">
                            <ul>
                              <li>
                                $0 when you pay via internet banking, direct
                                debit or automatic payment.
                              </li>
                              <li>
                                $2.70 payment handling fee for over the counter
                                payments at NZ Post Shop branches.
                              </li>
                            </ul>
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Other fees</strong>
                          </p>
                          <p className="w-100">
                            $5 for statement reprints or replacement cards
                          </p>
                        </div>
                      </StyledRatesBox>
                    )
                  },
                  {
                    id: 'interchange-fees',
                    title: 'Interchange fees',
                    content: (
                      <div className="accordion-custom">
                        <div css="padding: 20px 30px 0 30px">
                          Interchange fees are paid by a business that processes
                          card transactions on behalf of a merchant (acquirer)
                          to the card issuer (Latitude Financial Services
                          Limited in the case of Gem Visa). The interchange fees
                          payable for all Gem Visa domestic Credit Card
                          transactions are set out below.
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Industry program: Charities</strong>
                          </p>
                          <p className="w-100">0.39%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Strategic Merchants Card Present (CP) and Card Not
                              Present (CNP): Rate 1
                            </strong>
                          </p>
                          <p className="w-100">0.80%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Strategic Merchants (CP and CNP): Rate 2
                            </strong>
                          </p>
                          <p className="w-100">0.65%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Strategic Merchants (CP and CNP): Rate 3
                            </strong>
                          </p>
                          <p className="w-100">0.60%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Strategic Merchants (CP and CNP): Rate 4
                            </strong>
                          </p>
                          <p className="w-100">0.50%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Strategic Merchants (CP and CNP): Rate 5
                            </strong>
                          </p>
                          <p className="w-100">0.45%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Tokenized Online – Classic, Gold</strong>
                          </p>
                          <p className="w-100">0.55%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Industry Program: Insurance</strong>
                          </p>
                          <p className="w-100">$1</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Industry Program: Recurring Payment</strong>
                          </p>
                          <p className="w-100">0.70%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Industry Program: Government, Utilities and
                              Telecom
                            </strong>
                          </p>
                          <p className="w-100">$0.70</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Contactless</strong>
                          </p>
                          <p className="w-100">0.65%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Electronic</strong>
                          </p>
                          <p className="w-100">0.80%</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Standard</strong>
                          </p>
                          <p className="w-100">0.80%</p>
                        </div>
                      </div>
                    )
                  }
                ]
              }}
            />
          </AnalyticsLocationProvider>

          <AnalyticsLocationProvider location={ANALYTICS.LOCATION.FOOTER}>
            <Box
              css={`
                .container,
                .container-sm,
                .container-md,
                .container-lg,
                .container-xl {
                  max-width: 825px;
                }
              `}
            >
              <BasicFooter>
                <div className="container h-100 d-flex">
                  <StyledHomeLink
                    noStyle
                    href="/"
                    trackEventData={{ label: 'nav-home' }}
                  >
                    <Image
                      data-trackid="footer-logo"
                      className="logo"
                      src={logoWhite}
                      alt="Gem by Latitude"
                    />
                  </StyledHomeLink>
                </div>
              </BasicFooter>
            </Box>
          </AnalyticsLocationProvider>

          <Box.Section backgroundColor="#eff7ff">
            <Outer isResponsive>
              <Inner>
                <Heading6>Important information</Heading6>
                <Text>
                  <sup id="note-1">1</sup>&nbsp;Credit and lending criteria, $55
                  establishment fee and $65 annual fee (charged $32.50
                  half-yearly) apply. Prevailing interest rate (currently 29.49%
                  p.a.) applies to any remaining balance on the expiry date of
                  the interest free term. For cash advances, an interest rate of
                  29.95% p.a. and a $2 fee applies. Further information on rates
                  and fees can be found at gemfinance.co.nz. Available to
                  approved Gem Visa credit card customers only.
                </Text>
                <Text>
                  <sup id="note-2">2</sup>&nbsp;Long term interest free offers
                  may vary. Available at participating retailers to approved
                  applicants only. Conditions, fees and charges apply.
                </Text>
                <Text>
                  <sup id="note-3">3</sup>&nbsp;Rates and fees are effective as
                  of 12 June 2024 and are subject to change.
                </Text>
                <br />
                <br />
                <Heading6>
                  Financial Advice &mdash; Prescribed Statement Disclosure
                </Heading6>
                <Text>
                  You are protected by responsible lending laws. Because of
                  these protections, the recommendations given to you by us
                  about personal loans, credit cards and any credit related
                  insurance products are not regulated financial advice. This
                  means that duties and requirements imposed on people who give
                  financial advice do not apply to these recommendations. This
                  includes a duty to comply with a code of conduct and a
                  requirement to be licensed.
                </Text>
              </Inner>
            </Outer>
          </Box.Section>
        </Box>
      </main>
      <AnalyticsLocationProvider location={ANALYTICS.LOCATION.FOOTER}>
        <StyledFooter>
          <div className="container">
            <StyledFooterInner>
              <div>
                <StyledUl>
                  <li>
                    <Link href="/privacy/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions/">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link href="/security/">Security</Link>
                  </li>
                </StyledUl>
                <StyledDisclaimerText>
                  Gem Visa is provided by Latitude Financial Services Limited
                </StyledDisclaimerText>
              </div>
            </StyledFooterInner>
          </div>
        </StyledFooter>
      </AnalyticsLocationProvider>
    </Layout>
  );
};

const StyledRatesBox = styled(Box)`
  ul {
    list-style: none;
    padding-left: 20px;
  }
  li {
    position: relative;
    &:before {
      content: '•';
      color: ${COLOR.BLUE_LIGHT};
      position: absolute;
      left: -22px;
      font-size: 34px;
    }
  }
`;

const BasicHeader = styled(Box)`
  height: 80px;

  @media (min-width: ${BREAKPOINT.LG}) {
    height: 72px;
  }
`;

const StyledHomeLink = styled(Link)`
  display: flex;
  align-self: center;

  .logo {
    height: 48px;
  }

  /* required inside flex container for IE11 */
  svg.home-button__logo {
    display: block;
    flex: 0 0 auto;
    width: 164px;
    height: 27px;
  }
`;

const BasicFooter = styled(Box)`
  height: 80px;
  background-color: ${COLOR.BLUE_DEEP};

  @media (min-width: ${BREAKPOINT.LG}) {
    height: 72px;
  }
`;

const Inner = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: ${PADDING.P4} 0 ${PADDING.P48};
`;

const Outer = styled(Box)`
  position: relative;
  margin: 0 auto 0;
`;

const StyledFooter = styled.footer`
  padding: ${PADDING.P24} 0 ${PADDING.P32};
  background-color: #eff7ff;
  font-size: ${FONT_SIZE.SMALL};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: ${PADDING.P32};
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: ${MARGIN.M16};
  display: ${DISPLAY.FLEX};
  flex-wrap: ${FLEX_WRAP.WRAP};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  li {
    padding: 0 ${PADDING.P8};
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 0;
    justify-content: ${JUSTIFY_CONTENT.CENTER};

    li {
      padding: 0 ${PADDING.P16} 0 0;
    }
  }
`;

const StyledDisclaimerText = styled.p`
  text-align: ${ALIGN.CENTER};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 7px;
  }
`;

const StyledFooterInner = styled.div`
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  @media (min-width: ${BREAKPOINT.LG}) {
    display: ${DISPLAY.FLEX};
    flex-direction: ${FLEX_DIRECTION.ROW};
  }
`;

const Divider = () => {
  return (
    <hr
      css={`
        width: 100%;
        height: 1px;
        margin: 1px auto;
        background-color: ${COLOR.GREY16};
        color: ${COLOR.GREY16};
        border: 0 none;
      `}
    />
  );
};

const SectionHeading = styled.h2`
  margin: 50px 0;
  color: ${COLOR.BLACK};
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 10px;
    font-size: 36px;
    line-height: 40px;
  }
`;

export default GemVisaInfoPage;
